import axios from "axios";
import loader from "@/services/loader.service";

const { VUE_APP_DATOCMS_URL, VUE_APP_DATOCMS_API_TOKEN } = process.env;
const headers = {
  Authorization: `Bearer ${VUE_APP_DATOCMS_API_TOKEN}`,
};

const ApiDatoMsService = {
  /**
   * Set the GET HTTP request
   * @param { Object } query
   * @param { Object } variables
   * @returns {*}
   */
  get(query, variables) {
    loader.on();

    return axios
      .post(
        `${VUE_APP_DATOCMS_URL}`,
        { query: query.loc && query.loc.source.body, variables: variables },
        { headers }
      )
      .then((response) => {
        loader.off();

        return response.data.data;
      });
  },
};

export default ApiDatoMsService;
