<template>
  <!-- begin:: Aside -->
  <div id="kt_brand" ref="kt_brand" class="brand flex-column-auto">
    <div class="brand-logo">
      <router-link to="/">
        <img :src="siteLogo()" alt="Logo" />
      </router-link>
    </div>
  </div>
  <!-- end:: Aside -->
</template>

<style lang="scss" scoped>
.aside-toggle {
  outline: none;
}
</style>

<script>
import objectPath from "object-path";
import KTLayoutBrand from "@/assets/js/layout/base/brand.js";
import KTLayoutAsideToggle from "@/assets/js/layout/base/aside-toggle.js";

export default {
  name: "KTBrand",

  computed: {
    allowMinimize() {
      return !!this.layoutConfig("aside.self.minimize.toggle");
    },
  },

  mounted() {
    // Init Brand Panel For Logo
    KTLayoutBrand.init(this.$refs["kt_brand"]);

    // Init Aside Menu Toggle
    KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);
  },

  methods: {
    siteLogo() {
      const menuAsideLeftSkin = this.layoutConfig("brand.self.theme");
      // set brand logo
      const logoObject = this.layoutConfig("self.logo");

      let logo;

      if (typeof logoObject === "string") {
        logo = logoObject;
      }

      if (typeof logoObject === "object") {
        logo = objectPath.get(logoObject, menuAsideLeftSkin + "");
      }

      if (typeof logo === "undefined") {
        const logos = this.layoutConfig("self.logo");

        logo = logos[Object.keys(logos)[0]];
      }

      return process.env.BASE_URL + logo;
    },
  },
};
</script>

<style>
.brand img {
  height: 22px;
  margin-top: 33px;
  margin-bottom: 0 !important;
}

.aside-minimize:not(.aside-minimize-hover) {
  .brand-logo {
    width: 23px;
    overflow: hidden;
  }
}

@media (min-width: 992px) {
  .brand {
    background-color: transparent !important;
    height: 64px;
  }
}
</style>
