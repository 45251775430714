<template>
  <!-- begin:: Aside -->
  <div
    id="kt_aside"
    ref="kt_aside"
    class="aside aside-left aside-fixed d-flex flex-column flex-row-auto"
    @mouseover="mouseEnter"
    @mouseleave="mouseLeave"
  >
    <!-- begin:: Aside -->
    <KTBrand></KTBrand>
    <!-- end:: Aside -->

    <!-- begin:: Aside Menu -->
    <div id="kt_aside_menu_wrapper" class="aside-menu-wrapper flex-column-fluid">
      <div
        id="kt_aside_menu"
        ref="kt_aside_menu"
        class="aside-menu my-lg-0 my-4"
        data-menu-vertical="1"
        data-menu-dropdown-timeout="500"
        :class="asideMenuClass"
      >
        <!-- example static menu here -->
        <perfect-scrollbar
          class="aside-menu scroll"
          style="max-height: calc(100vh - 185px); position: relative"
        >
          <KTMenu :counter="counter" />
        </perfect-scrollbar>
        <ButtonElement
          v-if="briefTemplate?.url && userType === 'agent'"
          variant="primary"
          :text="$t('downloadBriefTpl')"
          class="aside-brief-btn"
          @click="dowloadBriefTemplate"
        ></ButtonElement>
        <AsideTgBtn/>
      </div>
    </div>
  </div>
  <!-- end:: Aside -->
</template>

<script>
import { mapGetters } from "vuex";
import KTBrand from "@/layouts/main/brand/Brand.vue";

import KTLayoutAside from "@/assets/js/layout/base/aside.js";
import KTLayoutAsideMenu from "@/assets/js/layout/base/aside-menu.js";
import KTMenu from "@/layouts/main/aside/Menu.vue";
import AsideTgBtn from '../../../components/AsideTgBtn.vue';
import ButtonElement from "@/components/ButtonElement";
import { documentFormData } from "@/views/employee/documents/gql/queries"
import apolloMixin from "@/mixins/apollo.mixin";
import axios from 'axios';

export default {
  name: "KTAside",

  components: {
    KTBrand,
    KTMenu,
    AsideTgBtn,
    ButtonElement,
  },

  mixins: [apolloMixin],

  props: {
    counter: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      insideTm: 0,
      outsideTm: 0,
      briefTemplate: {},
    };
  },

  computed: {
    ...mapGetters(["getClasses", "userType"]),

    /**
     * Get extra classes for menu based on the options
     */
    asideMenuClass() {
      const classes = this.getClasses("aside_menu");

      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }

      return null;
    },
  },

  async created() {
    await this.getBriefTeplate();
  },

  mounted() {
    this.$nextTick(() => {
      // Init Aside
      KTLayoutAside.init(this.$refs["kt_aside"]);

      // Init Aside Menu
      KTLayoutAsideMenu.init(this.$refs["kt_aside_menu"]);
    });
  },

  methods: {
    /**
     * Use for fixed left aside menu, to show menu on mouseenter event.
     */
    mouseEnter() {
      // check if the left aside menu is fixed
      if (document.body.classList.contains("aside-fixed")) {
        if (this.outsideTm) {
          clearTimeout(this.outsideTm);
          this.outsideTm = null;
        }

        // if the left aside menu is minimized
        if (document.body.classList.contains("aside-minimize")) {
          document.body.classList.add("aside-minimize-hover");

          // show the left aside menu
          document.body.classList.remove("aside-minimize");
        }
      }
    },

    /**
     * Use for fixed left aside menu, to show menu on mouseenter event.
     */
    mouseLeave() {
      if (document.body.classList.contains("aside-fixed")) {
        if (this.insideTm) {
          clearTimeout(this.insideTm);
          this.insideTm = null;
        }

        // if the left aside menu is expand
        if (document.body.classList.contains("aside-minimize-hover")) {
          // hide back the left aside menu
          document.body.classList.remove("aside-minimize-hover");
          document.body.classList.add("aside-minimize");
        }
      }
    },

    async getBriefTeplate() {
      const response = await this.$get(documentFormData, { name: "brief" });

      this.briefTemplate = response.document;
    },

    dowloadBriefTemplate() {
      if(this.briefTemplate?.url) {

        axios.get(`storage/private/${this.briefTemplate.url}`, {
          method: "GET",
          responseType: "blob",
        }).then((response) => {
          const href = URL.createObjectURL(response.data);
          const link = document.createElement("a");

          link.href = href;
          link.setAttribute("download", this.briefTemplate.filename);
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });

      }
    },
  },
};
</script>

<style>
.aside {
  transition: width 0.1s;
}

.aside-brief-btn {
  max-width: 192px;
  margin-left: auto;
  display: flex;
  margin-bottom: 1rem;
  margin-right: auto;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  width: 100%;
}

@media (max-width: 992px) {
  .aside {
    top: 54px;
  }
}

.aside-minimize-hover {
  -webkit-box-shadow: 4px 0 10px 0 rgba(30, 31, 45, 0.2);
  -moz-box-shadow: 4px 0 10px 0 rgba(30, 31, 45, 0.2);
  box-shadow: 4px 0 10px 0 rgba(30, 31, 45, 0.2);
}
</style>
