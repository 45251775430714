<template>
  <div id="kt_header_mobile" class="header" data-cy="header">
    <div class="brand-tools">
      <button
        id="kt_aside_toggle"
        ref="kt_aside_toggle"
        data-cy="aside-toggle"
        class="brand-toggle aside-toggle btn btn-sm p-0"
      >
        <span>
          <SvgIcon
            class="toggle-aside-icon"
            icon-folder="Navigation"
            icon-color="dark"
            icon-name="Close-2"
          />
        </span>
      </button>

      <button id="kt_aside_mobile_toggle" class="aside_mobile_toggle btn p-0 burger-icon">
        <span />
      </button>
    </div>

    <div v-if="userType !== 'employee'" class="referral-block">
      <span class="referral-link" data-cy="link-copy" @click="onClickCopy">
        {{ $t("label.referral.yourLink") }}
      </span>
      <span>
        <SvgIcon
          class="referral-link-icon"
          icon-folder="General"
          icon-color="primary"
          icon-name="Duplicate"
          icon-size="2x"
          @click="onClickCopy"
        />
      </span>
      <span class="referral"> {{ $t("label.referral.or") }} </span>
      <span class="referral-btn" @click="onClickAddReferral">
        {{ $t("label.referral.yourForm") }}
      </span>
      <span>
        <SvgIcon
          class="referral-btn-icon"
          icon-folder="General"
          icon-color="primary"
          icon-name="File"
          icon-size="2x"
          @click="onClickAddReferral"
        />
      </span>
    </div>

    <KTQuickUser class="w-1/4" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTLayoutAsideToggle from "@/assets/js/layout/base/aside-toggle";
import KTLayoutHeaderTopbar from "@/assets/js/layout/base/header-topbar";

import SvgIcon from "@/components/svg/SvgIcon.vue";
import notify from "@/services/notify.service";
import KTQuickUser from "@/layouts/main/extras/offcanvas/QuickUser.vue";

export default {
  name: "Header",

  components: {
    SvgIcon,
    KTQuickUser,
  },

  computed: {
    ...mapGetters(["userType", "userHash"]),

    referralLink() {
      return `${window.location.origin}/landing?agent=${this.userHash}`;
    },
  },

  mounted() {
    // Init Aside Menu Toggle
    KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);

    // Init Header Topbar For Mobile Mode
    KTLayoutHeaderTopbar.init(this.$refs["kt_aside_mobile_toggle"]);
  },

  methods: {
    onClickAddReferral() {
      this.$router.push({ name: "AgentReferralAdd" });
    },

    onClickCopy() {
      try {
        window.navigator.clipboard.writeText(this.referralLink);

        notify.success("copiedSuccessfully");
      } catch (error) {
        notify.error("copyError");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 53px;
  width: 100%;
  background-color: #ffffff;
  padding: 0 24px 0 10px;
  margin-bottom: 10px;

  .burger-icon span,
  span::before,
  span::after {
    width: 20px;
    height: 2px;
    border-radius: 0;
  }

  .burger-icon span::before {
    top: -6px;
    border-radius: 0;
  }

  .burger-icon span::after {
    top: 6px;
    border-radius: 0;
  }

  .referral-block {
    display: flex;
    align-items: center;

    .referral {
      margin: 0 5px;
    }

    .referral-link-icon,
    .referral-btn-icon {
      display: none !important;
    }

    .referral,
    .referral-link,
    .referral-btn {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #8c8c8c;
    }

    .referral-link,
    .referral-btn {
      cursor: pointer;
      color: #156fc1;
    }
  }
}

.aside-toggle {
  display: block;
}

.aside_mobile_toggle {
  display: none;
}

.aside-minimize:not(.aside-minimize-hover) .toggle-aside-icon {
  transform: rotate(180deg);
}

@media (max-width: 992px) {
  .header {
    padding: 0 24px;
  }

  .aside-toggle {
    display: none;
  }

  .aside_mobile_toggle {
    display: block;
  }
}

@media (max-width: 920px) {
  .header {
    .referral-block {
      .referral-link,
      .referral-btn {
        display: none;
      }

      .referral-link-icon,
      .referral-btn-icon {
        display: block !important;
      }
    }
  }
}
</style>
