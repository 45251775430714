<template>
  <div class="d-flex flex-column flex-root">
    <!--<Loader v-if="loaderEnabled" :logo="loaderLogo"></Loader>-->
    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <!-- begin:: Aside Left -->
      <KTAside v-if="asideEnabled" :counter="counter" />
      <!-- end:: Aside Left -->
      <div
        id="kt_wrapper"
        class="layout-bg d-flex flex-column flex-row-fluid wrapper"
      >
        <!-- :style="setLayoutMaxWith" -->
        <!-- begin:: Content -->
        <div id="kt_content" class="content d-flex flex-column xflex-column-fluid">
          <!-- begin:: Content Head -->
          <Header />

          <KTSubheader v-if="subheaderDisplay" :breadcrumbs="breadcrumbs" :title="pageTitle" />
          <!-- end:: Content Head -->

          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid">
            <div
              :class="{
                'container-fluid': contentFluid,
                container: !contentFluid,
              }"
            >
              <div :style="setContentMaxWith">
                <transition name="fade-in-up">
                  <router-view />
                </transition>
              </div>
            </div>
          </div>

          <KTFooter class="footer pt-6"></KTFooter>
        </div>
      </div>
    </div>
    <KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar>
    <TgNotificationPopup/>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import gql from "graphql-tag";
import ApiDatoсmsService from "@/services/apiDatoсms.service";

import KTAside from "@/layouts/main/aside/Aside";
import Header from "@/layouts/main/header/Header";
import KTFooter from "@/layouts/main/footer/Footer";
import HtmlClass from "@/services/htmlclass.service";
import KTSubheader from "@/layouts/main/subheader/Subheader";
import KTStickyToolbar from "@/layouts/main/extras/StickyToolbar";
import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from "@/store/modules/layout.module";
import TgNotificationPopup from '../../components/modal/TgNotificationPopup.vue';

const PIXELS_IN_REM = 14;

export default {
  name: "Layout",
  components: {
    KTAside,
    KTFooter,
    KTSubheader,
    KTStickyToolbar,
    Header,
    TgNotificationPopup,
  },

  data() {
    return {
      filtersToggle: true,
      counter: {},
    };
  },

  computed: {
    ...mapState("agent", ["allNewsId", "viewedNewsId"]),

    ...mapGetters(["breadcrumbs", "pageTitle"]),
    ...mapGetters("layoutFilter", ["filterFields"]),

    isFilterFields() {
      return !!this.filterFields || false;
    },

    layoutMaxWith() {
      return this.layoutConfig("content.maxWidth.value");
    },

    layoutDefaultMaxWith() {
      return Number(this.layoutDefaultConfig("content.maxWidth.default"));
    },

    setContentMaxWith() {
      let maxWith = "100%";

      if (this.layoutMaxWith > 0) {
        maxWith = `${this.layoutMaxWith / PIXELS_IN_REM}rem`;
      }

      if (this.layoutMaxWith === 0) {
        maxWith = `${this.layoutDefaultMaxWith / PIXELS_IN_REM}rem`;
      }

      return `max-width: ${maxWith}`;
    },

    setLayoutMaxWith() {
      let maxWith = "100%";
      const asideWidth = 240;

      if (this.layoutMaxWith > 0) {
        maxWith = `${(this.layoutMaxWith + asideWidth) / PIXELS_IN_REM}rem`;
      }

      if (this.layoutMaxWith === 0) {
        maxWith = `${(this.layoutDefaultMaxWith + asideWidth) / PIXELS_IN_REM}rem`;
      }

      return `max-width: ${maxWith}`;
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      return !!this.layoutConfig("toolbar.display");
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },

    newNewsCounter() {
      return this.allNewsId.length - this.viewedNewsId.length;
    },
  },

  watch: {
    viewedNewsId: "onChangeViewedNewsId",

    newNewsCounter: "onChangeNewNewsCounter",
  },

  created() {
    this.SET_NEW_USER_STATE();
    this.getAllNewsId();
    this.SET_VIEWED_NEWS_ID();
  },

  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init();
  },

  mounted() {
    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 2000);
  },

  methods: {
    ...mapMutations("layoutFilter", ["SET_FILTER_QUERY"]),
    ...mapMutations("agent", ["SAVE_ALL_NEWS_ID", "SET_VIEWED_NEWS_ID", "SAVE_VIEWED_NEWS_ID"]),
    ...mapMutations(["SET_NEW_USER_STATE"]),

    async getAllNewsId() {
      const { allRefProgramBlog1s } = await ApiDatoсmsService.get(
        gql`
          query allRefProgramBlog1S {
            allRefProgramBlog1s {
              id
            }
          }
        `
      );

      this.SAVE_ALL_NEWS_ID(allRefProgramBlog1s);
    },

    onChangeViewedNewsId() {
      this.SAVE_VIEWED_NEWS_ID();
    },

    onChangeNewNewsCounter() {
      if (this.newNewsCounter > 0) {
        this.counter = { ...this.counter, NewsCounter: this.newNewsCounter };
      } else {
        delete this.counter["NewsCounter"];
      }
    },
  },
};
</script>

<style lang="scss">
#kt_wrapper {
  width: 100%;
  max-width: initial;
}

#kt_content {
  min-height: 100vh;
}

.aside {
  width: 240px;
}

.aside-fixed .wrapper {
  padding-left: 240px;
}

.layout-bg {
  background-color: #f2f2f2;
  min-width: 1000px;
}

@media (min-width: 992px) {
  .content {
    padding: 25px 0 0 0;
  }
}

@media (max-width: 992px) {
  .aside-fixed .wrapper {
    padding-left: 0;
  }

  .content {
    padding: 0;
  }

  .layout-bg {
    min-width: 100%;
  }
}
</style>
