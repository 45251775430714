<template>
    <div v-if="!userTgBotSubscribe && userTgToken" class="aside-tg">
        <div class="aside-tg__inner">
            <div class="aside-tg__tooltip-handler">
                <svg-icon
                    icon-folder="Design"
                    icon-name="Info"
                />
                <div class="aside-tg__tooltip">
                    <p>{{ $t('tg.menuTooltipText_1') }}</p>
                    <p>{{ $t('tg.menuTooltipText_2') }} <span class="aside-tg__tooltip-accent">/start</span></p>
                </div>
            </div>
            <div class="aside-tg__text">{{ $t('tg.menuText') }}</div>
            <a :href="`https://t.me/atmosfera_refferal_bot?start=${userTgToken}`" target="_blank" class="aside-tg__btn tg__btn">
                <span>{{ $t('tg.btn') }}</span>
                <svg-icon
                    icon-folder="Design"
                    icon-name="TelegramIcon"
                />
            </a>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import SvgIcon from "@/components/svg/SvgIcon";

export default {
    name: "AsideTgBtn",
    components: {
      SvgIcon,
    },
    computed: {
        ...mapGetters(["userTgToken", "userTgBotSubscribe"]),
    },
}
</script>

<style lang="scss">
    .tg {
        &__btn {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            height: 32px;
            color: #fff;
            background: #3699FF;
            padding: 8px 1rem;
            border-radius: 6px;
            font-size: 14px;
            text-decoration: none;
            width: 100%;
            transition: all .3s ease 0s;

            &:hover {
                color: #fff;
                background: #187DE4;
            }

            .svg-icon {
                padding: 0 0 0 12px;
            }

            svg {
                fill: #fff !important;
            }

        }
    }

    .aside-tg {
        padding: 0 24px;
        
        &__inner {
            background: #fff;
            border-radius: 6px;
            padding: 12px 10px;
            position: relative;
        }

        &__text {
            padding-top: 8px;
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 16px;
            max-width: 145px;
        }

        &__tooltip {

            position: absolute;
            left: calc(100% + 26px);
            top: -50px;
            background: #fff;
            z-index: 1;
            border-radius: 6px;
            width: 100vw;
            max-width: 350px;
            padding: 20px 16px;
            font-size: 14px;
            line-height: 17px;
            transition: all .3s ease 0s;
            opacity: 0;
            visibility: hidden;

            &::after {
                content: ''; 
                position: absolute;
                left: -24px;
                top: calc(50% - 10px);
                border: 10px solid transparent;
                border-right: 16px solid #fff;
            }

            p {
                font-size: 14px;
                line-height: 17px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &-accent {
                color: #3699FF;
            }
            
            &-handler {
                position: absolute;
                right: 9px;
                top: 12px;
                .svg-icon {
                    padding: 0;
                }
                svg {
                    fill: #FFAB11;
                }

                &:hover {
                    .aside-tg {
                        &__tooltip {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
</style>