<template>
  <div class="topbar-item">
    <div id="kt_quick_user_toggle" data-cy="quick-user-button" class="btn btn-icon w-auto">
      <span>
        <SvgIcon
          class="icon"
          icon-folder="Communication"
          icon-size="2x"
          icon-color="dark"
          icon-name="User"
        />
      </span>
      <span class="user-name">
        <span class="font-weight-bold font-size-base user-text">
          {{ userFullName }}
        </span>
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      data-cy="quick-user-menu"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div class="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 class="font-weight-bold m-0">
          {{ $t("component.quickUser.userProfile") }}
        </h3>

        <div
          id="kt_quick_user_close"
          data-cy="user-menu-close-btn"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
        >
          <i class="ki ki-close icon-xs text-muted" />
        </div>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar class="offcanvas-content pr-5 mr-n5 scroll scroll-bar-item pb-8">
        <!--begin::Header-->
        <div class="d-flex flex-column align-items-start mt-5">
          <div class="d-flex align-items-center w-100">
            <div class="symbol symbol-100 mr-5">
              <img class="symbol-label menu-avatar" :src="userAvatar" alt="" />
            </div>

            <div class="user-info">
              <div class="user-info-item font-weight-bold font-size-h5 text-dark-75">
                {{ userFullName }}
              </div>

              <div class="navi">
                <div class="navi-item">
                  <span class="navi-link p-0">
                    <span class="user-info-item navi-text text-muted text-hover-primary">
                      {{ userEmail }}
                    </span>
                  </span>
                </div>
              </div>

              <div class="user-info-item text-muted mt-2">
                {{ role }}
              </div>
            </div>
          </div>

          <button
            data-cy="logout-button"
            class="btn btn-light-primary btn-bold px-18 py-3 mt-6"
            @click="onClickLogout"
          >
            {{ $t("label.auth.logOut") }}
          </button>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed my-7"></div>
        <!--begin::Nav-->
        <div class="navi navi-spacer-x-0 p-0">
          <router-link
            :to="profileRoute"
            data-cy="profile-edit-link"
            class="navi-item"
            @click.native="closeMenu"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <inline-svg :src="profileSvgPath" />
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">
                  {{ $t("component.quickUser.myProfile") }}
                </div>

                <div class="text-muted">
                  {{ $t("component.quickUser.myProfileDescription") }}
                </div>
              </div>
            </div>
          </router-link>

          <router-link
            v-if="isAgent"
            :to="requisitesRoute"
            data-cy="requisites-link"
            class="navi-item"
            @click.native="closeMenu"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <inline-svg :src="requisitesSvgPath" />
                  </span>
                </div>
              </div>

              <div class="navi-text">
                <div class="font-weight-bold">
                  {{ $t("component.quickUser.requisites") }}
                </div>

                <div class="text-muted">
                  {{ $t("component.quickUser.requisitesDescription") }}
                </div>
              </div>
            </div>
          </router-link>

          <router-link
            :to="changePasswordRoute"
            data-cy="change-password-link"
            class="navi-item"
            @click.native="closeMenu"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-danger">
                    <inline-svg :src="changePasswordSvgPath" />
                  </span>
                </div>
              </div>
              <div class="navi-text font-weight-bold">
                {{ $t("component.quickUser.changePassword") }}
              </div>
            </div>
          </router-link>
        </div>
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<script>
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import { mapGetters, mapActions } from "vuex";
import user from "@/configs/user.config.json";
import SvgIcon from "@/components/svg/SvgIcon.vue";

export default {
  name: "KTQuickUser",
  components: { SvgIcon },

  computed: {
    ...mapGetters(["userFullName", "userEmail", "userSymbols", "userRole", "userType"]),
    ...mapGetters("role", ["roleLabel"]),

    isAgent() {
      return this.userType === user.agent;
    },

    role() {
      return this.userRole ? this.roleLabel(this.userRole) : "";
    },

    profileRoute() {
      const routeName = this.userType === user.employee ? "EmployeeProfileEdit" : "ProfileEdit";

      return { name: routeName };
    },

    notificationRoute() {
      return { name: "Notifications" };
    },

    requisitesRoute() {
      return { name: "Requisites" };
    },

    changePasswordRoute() {
      const routeName =
        this.userType === user.employee ? "EmployeeChangePassword" : "ChangePassword";

      return { name: routeName };
    },

    profileSvgPath() {
      return `${process.env.BASE_URL}media/svg/icons/General/User.svg`;
    },

    notificationSvgPath() {
      return `${process.env.BASE_URL}media/svg/icons/General/Notifications1.svg`;
    },

    requisitesSvgPath() {
      return `${process.env.BASE_URL}media/svg/icons/General/Clipboard.svg`;
    },

    changePasswordSvgPath() {
      return `${process.env.BASE_URL}media/svg/icons/Home/Key.svg`;
    },

    userAvatar() {
      return `${process.env.BASE_URL}media/users/default.jpg`;
    },

    mailNotificationSvgPath() {
      return `${process.env.BASE_URL}media/svg/icons/Communication/Mail-notification.svg`;
    },
  },

  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },

  methods: {
    ...mapActions(["logout"]),

    onClickLogout() {
      this.logout();
    },

    closeMenu() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/sass/init";

#kt_quick_user {
  overflow: hidden;
}

#kt_quick_user_toggle {
  padding-left: 17px;
}

.topbar-item {
  width: auto;

  .icon {
    margin-right: 10px;
  }

  .user-name {
    display: flex;
    align-items: center;

    .user-text {
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: start;
    }
  }

  .user-info {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    overflow: hidden;

    .user-info-item {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

@media (max-width: 992px) {
  .offcanvas {
    top: 53px;
  }
}

@media (max-width: 770px) {
  .topbar-item {
    .icon {
      margin-right: 0px;
    }

    .user-name {
      display: none;
    }
  }
}

// Mobile mode
@include media-breakpoint-down(xs) {
  .menu-avatar {
    width: 80px !important;
    height: 80px !important;
  }
}

.scroll-bar-item {
  max-height: 90vh;
  position: relative;
}
</style>
