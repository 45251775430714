<template>
  <ul class="menu-nav">
    <template v-for="(item, index) in menuOptions">
      <template v-if="!item.submenu && !item.section">
        <router-link
          v-if="item.page"
          :key="index"
          v-slot="{ href, navigate, isActive, isExactActive }"
          :data-cy="`menu-link-${item.page}`"
          :to="{ name: item.page }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="setActiveClass(isActive, isExactActive)"
            @click="closeSubmenu"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <SvgIcon
                v-if="item.svgIcon"
                class="menu-icon"
                :icon-folder="item.svgIcon.folder"
                :icon-name="item.svgIcon.name"
              />

              <i v-else-if="item.icon" class="menu-icon fa-fw" :class="item.icon" />

              <span class="menu-text">
                {{ $t(item.translate) }}
              </span>

              <span v-if="item.counter && counter[item.counter]" class="menu-counter">
                {{ counter[item.counter] }}
              </span>
            </a>
          </li>
        </router-link>

        <li
          v-else-if="item.url"
          :key="index"
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
        >
          <a :href="item.url" :target="getTarget(item.target)" class="menu-link">
            <SvgIcon
              v-if="item.svgIcon"
              class="menu-icon"
              :icon-folder="item.svgIcon.folder"
              :icon-name="item.svgIcon.name"
            />

            <i v-else-if="item.icon" class="menu-icon fa-fw" :class="item.icon" />

            <span class="menu-text">
              {{ $t(item.translate) }}
            </span>

            <span v-if="item.counter && counter[item.counter]" class="menu-counter">
              {{ counter[item.counter] }}
            </span>
          </a>
        </li>
      </template>

      <li v-if="item.section" :key="index" class="menu-section">
        <h4 class="menu-text">{{ $t(item.translate) }}</h4>
      </li>

      <div v-if="item.divider" :key="index" class="menu-divider" />

      <Submenu v-if="item.submenu" :key="index" :item="item" />
    </template>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
import user from "@/configs/user.config.json";
import employeeMenuConfig from "@/configs/employeeMenu.config.json";
import agentMenuConfig from "@/configs/agentMenu.config.json";
import Submenu from "./Submenu";
import SvgIcon from "@/components/svg/SvgIcon";

export default {
  name: "KTMenu",

  components: {
    Submenu,
    SvgIcon,
  },

  props: {
    counter: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    ...mapGetters(["userType", "userAgentType"]),

    menuOptions() {

      let menuCfg = agentMenuConfig.aside.items;

      if(this.userType === user.employee) {
        menuCfg = employeeMenuConfig.aside.items
      } else if(this.userType === "agent" && this.userAgentType === "company") {
        menuCfg.splice(3, 0, {
          "root": true,
          "svgIcon": {
            "folder": "Communication",
            "name": "Address-card"
          },
          "page": "CompanyAgentList",
          "translate": "title.agent.list"
        });
      }

      return menuCfg;
    },
  },

  methods: {
    closeSubmenu() {
      const submenuItem = document.querySelector(".menu-item-open");

      if (submenuItem) submenuItem.classList.remove("menu-item-open");
    },

    setActiveClass(isActive, isExactActive) {
      return [isActive && "menu-item-active", isExactActive && "menu-item-active"];
    },

    getTarget(target) {
      return target ? target : "";
    },
  },
};
</script>

<style scoped lang="scss">
.aside-menu .menu-nav > .menu-item > .menu-link {
  overflow-x: hidden;

  .menu-text {
    color: #ffff;
    white-space: nowrap;
  }

  .menu-icon {
    color: #ffffff !important;
    fill: #ffffff !important;

    &:deep() {
      g [fill] {
        fill: #ffffff !important;
        opacity: 1;
      }

      #check-path {
        opacity: 1;
      }

      #info-rect,
      #wallet-path,
      #check-path {
        fill: #1e1e2c !important;
      }
    }
  }

  &:hover {
    background-color: #f2f2f2 !important;
    border-left: 6px solid #156fc1;
    box-sizing: border-box;
    padding-left: 20px;

    .menu-text,
    .menu-counter {
      color: #000000 !important;
    }

    .menu-icon {
      &:deep() {
        g [fill] {
          fill: #1e1e2c !important;
          opacity: 1;
        }

        #info-rect,
        #wallet-line,
        #wallet-path,
        #check-path {
          fill: #ffffff !important;
        }
      }
    }
  }
}

.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link {
  background-color: #f2f2f2;
  border-left: 6px solid #156fc1;
  box-sizing: border-box;
  padding-left: 20px;

  .menu-icon {
    &:deep() {
      g [fill] {
        fill: #1e1e2c !important;
        opacity: 1;
      }

      #info-rect,
      #wallet-line,
      #wallet-path,
      #check-path {
        fill: #ffffff !important;
      }
    }
  }

  .menu-text {
    color: #000000;
  }

  .menu-counter {
    color: #000000;
  }
}

.btn {
  padding: 0;
}

.menu-item {
  position: relative;
}

.menu-counter {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 20px;
  height: 15px;
  width: 15px;
  border-radius: 100%;
  background-color: red;
  margin: auto 0;
  color: white;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-divider {
  width: 100%;
  height: 1px;
  background-color: #323249;
  margin: 24px 0;
}
</style>
