<template>
  <!-- begin:: Footer -->
  <div id="kt_footer" class="footer pt-0 pb-4 pr-6 pl-4 d-flex flex-lg-column">
    <div class="d-flex align-items-center justify-content-between">
      <Lang />
      <div class="text-dark"></div>
      <div>
        <span class="text-muted font-weight-bold"> 2021 &nbsp;&copy;&nbsp; </span>
        <span>
          <b-link :href="atmosferaSiteUrl" target="_blank">Atmosfera</b-link>
        </span>
      </div>
    </div>
  </div>
  <!-- end:: Footer -->
</template>

<script>
import Lang from "@/layouts/main/header/Lang";

export default {
  name: "KTFooter",
  components: {
    Lang,
  },
  computed: {
    atmosferaSiteUrl() {
      return "https://www.atmosfera.ua";
    },
  },
};
</script>
