<template>
  <li
    aria-haspopup="true"
    data-menu-toggle="hover"
    class="menu-item menu-item-submenu"
    :class="isMenuOpen"
  >
    <a href="#" class="menu-link menu-toggle">
      <SvgIcon
        v-if="item.svgIcon"
        class="menu-icon"
        :icon-folder="item.svgIcon.folder"
        :icon-name="item.svgIcon.name"
      />

      <i v-else-if="item.icon" class="menu-icon fa-fw" :class="item.icon" />

      <span class="menu-text">{{ $t(item.translate) }}</span>
      <i class="menu-arrow" />
    </a>

    <div class="menu-submenu">
      <span class="menu-arrow" />
      <ul class="menu-subnav">
        <template v-for="(subitem, idx) in item.submenu">
          <router-link
            v-if="subitem.page"
            :key="idx"
            v-slot="{ href, navigate, isActive, isExactActive }"
            :to="{ name: subitem.page }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="setActiveClass(isActive, isExactActive)"
            >
              <a ref="submenuLink" :href="href" class="menu-link submenu-item" @click="navigate">
                <SvgIcon
                  v-if="subitem.svgIcon"
                  class="menu-icon"
                  :icon-folder="subitem.svgIcon.folder"
                  :icon-name="subitem.svgIcon.name"
                />

                <i v-else-if="subitem.icon" class="menu-icon fa-fw" :class="subitem.icon" />

                <i
                  v-else-if="item.bullet"
                  class="menu-bullet"
                  :class="`menu-bullet-${item.bullet}`"
                >
                  <span />
                </i>
                <span class="menu-text">
                  {{ $t(subitem.translate) }}
                </span>
              </a>
            </li>
          </router-link>

          <li v-else :key="idx" aria-haspopup="true" data-menu-toggle="hover" class="menu-item">
            <a :href="subitem.url" class="menu-link submenu-item">
              <SvgIcon
                v-if="subitem.svgIcon"
                class="menu-icon"
                :icon-folder="subitem.svgIcon.folder"
                :icon-name="subitem.svgIcon.name"
              />

              <i v-else-if="subitem.icon" class="menu-icon fa-fw" :class="subitem.icon" />

              <i v-else-if="item.bullet" class="menu-bullet" :class="`menu-bullet-${item.bullet}`">
                <span />
              </i>
              <span class="menu-text">
                {{ $t(subitem.translate) }}
              </span>
            </a>
          </li>
        </template>
      </ul>
    </div>
  </li>
</template>

<script>
import SvgIcon from "@/components/svg/SvgIcon";

export default {
  name: "Submenu",
  components: {
    SvgIcon,
  },

  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    isOpen: false,
  }),

  computed: {
    isMenuOpen: {
      get() {
        return {
          "menu-item-open": this.isOpen,
        };
      },
      set(bool) {
        this.isOpen = bool;
      },
    },
  },

  mounted() {
    const [rootRoute] = this.$route.path.split("/").filter((item) => item !== "");

    if (this.$refs.submenuLink) {
      this.isMenuOpen = this.$refs.submenuLink.some((link) => link.href.includes(rootRoute));
    }
  },

  methods: {
    setActiveClass(isActive, isExactActive) {
      return [isActive && "menu-item-active", isExactActive && "menu-item-active"];
    },
  },
};
</script>
