<template>
  <div id="kt_subheader" class="subheader flex flex-column pb-2 pb-lg-7" :class="subheaderClasses">
    <template>
      <div
        class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
        :class="{ 'container-fluid': widthFluid, container: !widthFluid }"
      >
        <div class="d-flex align-items-center flex-wrap mr-1">
          <h1 class="text-dark header-title font-weight-bold my-2 mr-5">
            {{ title }}
          </h1>
          <ul
            class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 mt-4 mb-2"
          >
            <li class="breadcrumb-item">
              <router-link :to="homePageRoute" class="subheader-breadcrumbs-home d-inline-block">
                <i class="flaticon2-shelter text-muted icon-1x"></i>
              </router-link>
            </li>

            <template v-for="(breadcrumb, i) in breadcrumbs">
              <li v-if="breadcrumb.route" :key="`${i}-${breadcrumb.id}`" class="breadcrumb-item">
                <router-link :key="i" :to="breadcrumb.route" class="text-muted d-inline-block">
                  {{ breadcrumb.title }}
                </router-link>
              </li>
            </template>
          </ul>
        </div>
        <div class="d-flex align-items-center"></div>
      </div>
    </template>
  </div>
</template>

<script>
import user from "@/configs/user.config.json";
import homePage from "@/configs/homePage.config.json";
import { mapGetters } from "vuex";

import SvgIcon from "@/components/svg/SvgIcon.vue";
import KTQuickUser from "@/layouts/main/extras/offcanvas/QuickUser.vue";

export default {
  name: "KTSubheader",

  components: {
    SvgIcon,
    KTQuickUser,
  },

  props: {
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
  },

  computed: {
    ...mapGetters(["userType", "userHash"]),

    referralLink() {
      return `${window.location.origin}/landing?agent=${this.userHash}`;
    },

    homePageRoute() {
      const homePageRouteName =
        this.userType === user.employee ? homePage.employee : homePage.agent;

      return { name: homePageRouteName };
    },
    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("subheader.width") === "fluid";
    },

    subheaderClasses() {
      const classes = [];
      const style = this.layoutConfig("subheader.style");

      if (style) {
        classes.push(style);

        if (style === "solid") {
          classes.push("bg-white");
        }
      }

      return classes.join(" ");
    },
  },
};
</script>

<style lang="scss">
.header-title {
  font-size: 16px;
  line-height: 19px;
}

.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>
