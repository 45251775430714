<template>
    <div v-if="show && userTgToken" class="tg-notification-popup">
        <svg-icon
            @click="close"
            icon-folder="Navigation"
            icon-name="Close"
            class="tg-notification-popup__close"
        />
        <div class="tg-notification-popup__text">{{ $t('tg.menuTooltipText_1') }}</div>
        <div class="tg-notification-popup__bottom">
            <div class="tg-notification-popup__text">{{ $t('tg.menuTooltipText_2') }} <span>/start</span></div>
            <a :href="`https://t.me/atmosfera_refferal_bot?start=${userTgToken}`" target="_blank" class="tg__btn">
                <span>{{ $t('tg.btn') }}</span>
                <svg-icon
                    icon-folder="Design"
                    icon-name="TelegramIcon"
                />
            </a>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import SvgIcon from "@/components/svg/SvgIcon";

export default {
    name: "TgNotificationPopup",
    components: {
      SvgIcon,
    },
    computed: {
        ...mapGetters(["userId", "userTgToken", "userTgBotSubscribe"]),
    },
    data() {
        return {
            show: false,
        };
    },
    mounted() {
        if(!this.userTgBotSubscribe){
            this.handlePopupShow()
        } else {
            localStorage.removeItem("tg_bot_subscribe_popup")
        }
    },
    methods: {
        close() {
            this.show = false;
            const storage = JSON.parse(localStorage.getItem("tg_bot_subscribe_popup"));
            const now = Date.now();
            const lifetime = 1000 * 60 * 60 * 168;

            for(let i = 0; i < storage.length; i++) {
                if(storage[i].id === this.userId) {
                    storage[i].closed = true;

                    storage[i].expire = now + lifetime;
                }
            }

            localStorage.setItem("tg_bot_subscribe_popup", JSON.stringify(storage));
        },
        handlePopupShow() {
            let storage = localStorage.getItem("tg_bot_subscribe_popup");
            const now = Date.now();

            if(!storage) {
                const data = [{
                    id: this.userId,
                    expire: 0,
                    closed: false,
                }];

                localStorage.setItem("tg_bot_subscribe_popup", JSON.stringify(data));
                storage = JSON.parse(localStorage.getItem("tg_bot_subscribe_popup"));
            } else {
                storage = JSON.parse(storage);

                if(!storage.find((row) => row.id === this.userId)) {
                    storage.push({
                        id: this.userId,
                        expire: 0,
                        closed: false,
                    });

                    localStorage.setItem('popups', JSON.stringify(storage));
                }
            }

            const currentPopupState = storage.find((row) => row.id === this.userId);

            if(currentPopupState && currentPopupState.closed === false || now > currentPopupState.expire) {
                setTimeout(() => {
                    for(let i = 0; i < storage.length; i++) {
                        if(storage[i].id === this.userId) {
                            this.show = true;
                            storage[i].closed = false;
                            storage[i].expire = 0;
                            localStorage.setItem("tg_bot_subscribe_popup", JSON.stringify(storage));
                        }
                    }
                }, 0);
            }
        }
    }
}
</script>

<style lang="scss">
    .tg-notification-popup {
        position: fixed;
        width: 90%;
        max-width: 700px;
        background: #fff;
        margin: auto;
        left: 0;
        right: 0;
        z-index: 100;
        bottom: 27px;
        border-radius: 6px;
        padding: 32px 16px 20px 16px;
        box-shadow: 0 2px 6px rgba(0,0,0,0.1);

        &__close {
            position: absolute;
            right: 0;
            top: -4px;

            svg g [fill] {
                fill: #000;
            }
        }

        &__text {
            span {
                color: #3699FF;
            }
        }

        &__bottom {
            margin-top: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .tg__btn {
            max-width: 174px;
        }

        @media screen and (max-width: 767px) {
            &__bottom {
                flex-direction: column;
                align-items: flex-start;
            }
            .tg__btn {
                max-width: initial;
                margin-top: 8px;
            }
        }
    }
</style>